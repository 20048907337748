<template>
  <div class="container order-container">
    <div class="row row-order-success">
      <div class="col-12 header-order-success">
        <cart-container>
          <template
            slot="cartitem"
          >
            <div class="mt-3 mb-3 cart-item snack-cart-item">
              <h3>{{ $t('Order.ORDER_SUCCESS') }}</h3>
              <p class="mt-2 item-message snacko-item-message">{{ $t('Order.SNACK_MESSAGE') }} {{ formatDate }} {{ $t('Order.SNACK_MID_MESSAGE') }} {{ getTime }} {{ $t('Order.SNACK_LAST_MESSAGE') }}</p>
              <p> {{ $t('Order.SNACK_CONFIRMATION_MESSAGE') }}{{ sandwichSuccessData.email_id }}{{ $t('Order.SNACK_MIDDLE_MESSAGE')}} {{ $t('Order.SNACK_AFTER_CONFRIMATION_MESSAGE') }} </p>
              <!-- <a class="btn btn-theme mt-4 button-element" v-if="sandwichSuccessData.deep_link && !sandwichSuccessData.payment_type" href="#" @click="openLink">Test</a>
              <a href="#" class="btn btn-theme mt-4 button-element" v-else @click="goToHome">{{ $t('Order.SHOP_MORE_BUTTON_TEXT') }}</a> -->
              <a href="#" class="btn btn-theme mt-4 button-element" @click="goToHome">{{ $t('Order.SHOP_MORE_BUTTON_TEXT') }}</a>
            </div>
          </template>
        </cart-container>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { format } from 'date-fns';
import { Store } from 'vuex/dist/vuex.common.js';


  const CartContainer = () => import('@/components/cart/CartContainer')

  export default {
    name: 'OrderSuccess',

    data () {
      return {
        productType: '',
      }
    },

    mounted() {
      this.productType = localStorage.getItem('product_type');
    },
    computed: {
      ...mapGetters({ sandwichSuccessData:'getSandwichSuccessData' }),

      getTime () {
        let filterTime = this.sandwichSuccessData.order_date_time.split(' ');
        let filterSeconds = filterTime[1].split(':');
        return filterSeconds[0].concat(':' + filterSeconds[1]);
      },

      formatDate()
      {
        if(this.productType == 'sandwich')
        {
          let getDate = this.sandwichSuccessData.order_date_time.split(' ');
          let today  = new Date(getDate[0]);
          return [
            // this.$t('core.Base.DAYS_OF_WEEK.' + format(today,'EEEE')),
            today.getDate(),
            this.$t('core.Base.MONTH.' + format(today, 'MMMM')).toLowerCase()
          ].join(' ');
        }
        else
        {
          return '';
        }
      },
    },

    methods: {
      goToHome () {

        window.location = '/products/' + this.productType + '?settings_id='+ this.sandwichSuccessData.setting_id+'&lang='+ this.$store.state.appLang +'&close=true'
        //   //window.Navigation.postMessage('navigate-to-home');
        // }
      },
      openLink(){
        if(this.sandwichSuccessData.deep_link){
          console.log(this.sandwichSuccessData.deep_link.indexOf("deeplink://"));
          if(this.sandwichSuccessData.deep_link.indexOf("deeplink://") < 0) {
            window.open('//'+this.sandwichSuccessData.deep_link);
          }else{
            window.open(this.sandwichSuccessData.deep_link);
          }
        }
      }
    },

    components: {
      CartContainer
    }
  }
</script>
